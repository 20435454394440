import { Component, h, signal, tag } from "omi";
import classNames from "classnames";

import { NWBadge } from "@/components/atoms/nw-badge";
import { NWButton } from "@/components/atoms/nw-button";
import { NWIcon } from "@/components/atoms/nw-icon";
import { triggerToast } from "@/components/base/nw-toast";
import { openLoginModal, openProModal } from "@/components/modals/events";
import { useProjectUpdate } from "@/store/project/mutations";
import { ProjectStatus } from "@/api";
import { Store } from "@/store";
import { tailwind } from "@/tailwind";

type Props = {};

@tag("pages-project-features-pro-paywall")
export class ProjectProPaywall extends Component<Props> {
  static css = [
    tailwind,
    `
    @keyframes fadeOut {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
        visibility: hidden;
      }
    }

    .container-fade-out {
      animation: fadeOut 0.5s ease-in-out forwards;
    }
    `,
  ];

  private isAnimating = signal<boolean>(false);
  private projectUpdateMutation = useProjectUpdate();

  handlePaywallClick = async () => {
    if (!Store.user.isLoggedIn.value) {
      openLoginModal();
      return;
    }

    if (!Store.user.isPro()) {
      openProModal();
      return;
    }

    const projectId = Store.project.id.value;
    if (!projectId) return;

    const status = Store.project.status.value ?? ProjectStatus.PROJECT_INCOMPLETE;
    await this.projectUpdateMutation.mutate(projectId, { status, locked: false });

    this.isAnimating.value = true;

    setTimeout(() => {
      Store.project.setProProjectUnlocked();

      import("@/services/analyticsService").then(({ useAnalyticsService }) => {
        useAnalyticsService().trackProjectUnlocked(projectId);
      });

      triggerToast({ message: "PRO Project unlocked! 🚀", position: "top" });
    }, 500);
  };

  render() {
    return (
      <div className={classNames("relative", { "container-fade-out": this.isAnimating.value })}>
        <div className="absolute -z-10 inset-x-0 inset-y-0 bg-gradient-to-t from-white from-80% pointer-events-none" />

        <div className="min-w-fit flex flex-col items-center justify-center text-center px-4 pb-32 space-y-4 paywall-fade">
          <div className="flex flex-col relative items-center justify-center space-y-1 max-w-screen-sm">
            <NWIcon name="keyhole" className="z-10 transition-transform duration-300" width={110} height={190} />
            <NWBadge className="absolute bottom-0 -rotate-6 z-10" color="brand">
              PRO
            </NWBadge>
          </div>

          <div className="flex flex-col items-center justify-center space-y-1 max-w-screen-sm">
            <h2 className="text-2xl font-semibold text-gray-900">Take your skills further.</h2>
            <p className="text-lg text-gray-600">
              Dive into advanced and exciting projects that will deepen your understanding. You got this!
            </p>
          </div>

          <div className="p-6 relative z-10">
            <NWButton
              color="brand"
              size="xl"
              onClick={this.handlePaywallClick}
              className="relative"
              loading={this.projectUpdateMutation.signal.isLoading.value || this.isAnimating.value}
            >
              {Store.user.isPro() ? "You're PRO! Jump in" : "Unlock PRO projects"}
            </NWButton>
          </div>
        </div>
      </div>
    );
  }
}
